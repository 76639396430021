import { Environment } from 'api/config';

// TODO move this to somewhere which makes more sense

const environment = import.meta.env.VITE_ENV;

export const getCurrentEnvironment = (): Environment => {
  if (!environment) {
    return Environment.DEV;
  }
  return environment as Environment;
};
