import { Server } from 'api/types';

export const getServer = (): Server => {
  const server = document.currentScript?.getAttribute?.('server');
  if (server) {
    return server as Server;
  }
  const path = window.location.pathname;

  if (path.includes(`/${Server.US}`)) {
    return Server.US;
  } else if (path.includes(`/${Server.EU}`)) {
    return Server.EU;
  }
};
