/// <reference types="vite-plugin-svgr/client" />
import { LinearProgress } from '@mui/material';

import BRMLogo from 'assets/SVGs/bikedotrent-logo.svg?react';

import './styles.scss';
import { ReactNode } from 'react';
import classNames from 'classnames';
import { Schedule } from '@mui/icons-material';

interface LoadingScreenProps {
  brmLogo?: boolean;
  errorMessage?: string;
  takingTooLong?: boolean;
  children?: ReactNode;
}

const LoadingScreen = (props: LoadingScreenProps) => {
  const { errorMessage, takingTooLong, children } = props;

  return (
    <div className="centerOnLargerScreens maxHeight">
      <div
        className={classNames(
          'page',
          'noShadow',
          'whiteSmoke',
          children && 'LoadingScreen--with-error'
        )}
      >
        <div className="page-content allCenter">
          <BRMLogo className="LoadingScreen-BRMlogo" />

          {errorMessage ? (
            !children && errorMessage
          ) : (
            <LinearProgress className="LoadingScreen-loader" />
          )}

          {takingTooLong && (
            <div
              style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
            >
              <Schedule />
              <p>Hold tight! We're getting everything ready for you</p>
            </div>
          )}

          {children}
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
