import { Server } from 'api/types';
import { get } from 'idb-keyval';

export enum Environment {
  PROD = 'prod',
  STAGING = 'staging',
  TEST = 'test',
  TEST_PREVIEW = 'test-preview',
  DEV = 'dev',
}

const BRM_US_TEST_API_URL = import.meta.env.VITE_US_TEST_API_URL;
const BRM_EU_TEST_API_URL = import.meta.env.VITE_EU_TEST_API_URL;
const BRM_US_STAGING_API_URL = import.meta.env.VITE_US_STAGING_API_URL;
const BRM_EU_STAGING_API_URL = import.meta.env.VITE_EU_STAGING_API_URL;
const BRM_US_API_URL = import.meta.env.VITE_US_API_URL;
const BRM_EU_API_URL = import.meta.env.VITE_EU_API_URL;
const environment = import.meta.env.VITE_ENV;

const BRM_US_TEST_PAYMENT_SERVICE =
  'https://payment-service-m3c5iwmuna-ue.a.run.app';
const BRM_EU_TEST_PAYMENT_SERVICE =
  'https://payment-service-lb4fea3spa-ew.a.run.app';
const BRM_US_STAGING_PAYMENT_SERVICE =
  'https://payment-service-a22xkupxha-ue.a.run.app';
const BRM_EU_STAGING_PAYMENT_SERVICE =
  'https://payment-service-codafektvq-ew.a.run.app';
const BRM_US_PRODUCTION_PAYMENT_SERVICE =
  'https://payment-service-f4aa7x745q-ue.a.run.app';
const BRM_EU_PRODUCTION_PAYMENT_SERVICE =
  'https://payment-service-gl4ftmvbcq-ew.a.run.app';

const BIKE_RENT_SERVICE_LOCAL = 'http://localhost:8080';
const BIKE_RENT_SERVICE_TEST =
  'https://user-service-gateway-7hzyy4yx.uc.gateway.dev';
const BIKE_RENT_SERVICE_STAGING =
  'https://user-service-bvo5vtr6.uc.gateway.dev';
const BIKE_RENT_SERVICE_PROD = 'https://user-service-5jlu88xd.uc.gateway.dev';

export const getBikeRentServiceURL = async () => {
  if (environment !== Environment.PROD) {
    const override = await checkIndexDb('userservice');
    if (override) {
      return override;
    }
  }
  switch (environment) {
    case Environment.PROD:
      return BIKE_RENT_SERVICE_PROD;
    case Environment.STAGING:
      return BIKE_RENT_SERVICE_STAGING;
    default:
      return BIKE_RENT_SERVICE_TEST;
  }
};

export const getPaymentServiceURL = async (server: Server) => {
  if (environment !== Environment.PROD) {
    const override = await checkIndexDb('paymentservice');
    if (override) {
      return override;
    }
  }
  if (environment === Environment.PROD) {
    return server === Server.US
      ? BRM_US_PRODUCTION_PAYMENT_SERVICE
      : BRM_EU_PRODUCTION_PAYMENT_SERVICE;
  } else if (environment === Environment.STAGING) {
    return server === Server.US
      ? BRM_US_STAGING_PAYMENT_SERVICE
      : BRM_EU_STAGING_PAYMENT_SERVICE;
  } else {
    return server === Server.US
      ? BRM_US_TEST_PAYMENT_SERVICE
      : BRM_EU_TEST_PAYMENT_SERVICE;
  }
};

export const getApiUrl = async (server: Server) => {
  if (environment !== Environment.PROD) {
    const override = await checkIndexDb('server');
    if (override) {
      return override;
    }
  }
  if (environment === Environment.PROD) {
    return server === Server.US ? BRM_US_API_URL : BRM_EU_API_URL;
  } else if (environment === Environment.STAGING) {
    return server === Server.US
      ? BRM_US_STAGING_API_URL
      : BRM_EU_STAGING_API_URL;
  } else {
    const overrideAPI = localStorage.getItem('OVERRIDE_API_URL');
    if (overrideAPI) {
      return overrideAPI;
    }
    return server === Server.US ? BRM_US_TEST_API_URL : BRM_EU_TEST_API_URL;
  }
};

const checkIndexDb = async (type: string) => {
  return get('apiOverride').then((res) => {
    if (res) {
      return res[type];
    }
  });
};
