import { useEffect, useState, Suspense, lazy, useRef } from 'react';

import LoadingScreen from 'components/Loader';

import { getServer } from 'utils/getServer';

import './App.scss';

const BookingLoader = lazy(() => import('./BookingLoader'));
const CancellationLoader = lazy(() => import('./CancellationLoader'));

const server = getServer();

const LOADER_TAKING_TOO_LONG_MS = 3500;

const AppWrapper = () => {
  const loadStart = useRef<Date>();
  const [page, setPage] = useState<string>('');

  const [takingTooLongToLoad, setTakingTooLong] = useState<boolean>(false);

  useEffect(() => {
    loadStart.current = new Date();
    const pathname = window.location.pathname;
    const path = pathname.replace(`/${server}`, '');

    if (path === `/cancel`) {
      setPage('cancelreservation');
    } else if (path === '/login') {
      setPage('loginvalidation');
    } else {
      setPage('cbp');
    }

    const interval = setInterval(() => {
      const currentTime = new Date();
      const timeElapsed = currentTime.getTime() - loadStart.current.getTime();

      if (timeElapsed > LOADER_TAKING_TOO_LONG_MS) {
        setTakingTooLong(true);
        clearInterval(interval);
      }
    }, 100);

    return () => clearInterval(interval);
  }, []);

  return (
    <Suspense fallback={<LoadingScreen takingTooLong={takingTooLongToLoad} />}>
      {(() => {
        if (page === 'cancelreservation') {
          return <CancellationLoader />;
        } else if (page === 'cbp') {
          return <BookingLoader />;
        } else {
          return <></>;
        }
      })()}
    </Suspense>
  );
};

export default AppWrapper;
