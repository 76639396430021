import { createRoot } from 'react-dom/client';

import { LicenseInfo } from '@mui/x-license-pro';

import AppWrapper from './AppWrapper';
import './index.css';

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_LICENSE_KEY);

import { initializeApp } from 'firebase/app';
import { initializeAnalytics } from 'firebase/analytics';
import {
  testFirebaseConfig,
  productionFirebaseConfig,
  stagingFirebaseConfig,
} from './firebaseConfig';
import version from '../package.json';
import { Environment } from 'api/config';
import { getCurrentEnvironment } from 'config';

const environment = getCurrentEnvironment();

const createFirebaseApp = () => {
  if (environment === Environment.PROD) {
    return initializeApp(productionFirebaseConfig);
  } else if (environment === Environment.STAGING) {
    return initializeApp(stagingFirebaseConfig);
  }
  return initializeApp(testFirebaseConfig);
};

const app = createFirebaseApp();

const data = {
  app_id: testFirebaseConfig.appId,
  app_version: version.version,
  app_name: 'CBP',
};
initializeAnalytics(app, {
  config: data,
});

const container = document.querySelector('#brm_cbp');

const root = createRoot(container);
root.render(<AppWrapper />);
