export const testFirebaseConfig = {
  apiKey: 'AIzaSyDCS2GRw_rzeCQiIeCNwFtXU3mi9xzKDug',
  authDomain: 'bike-rent-1.firebaseapp.com',
  projectId: 'bike-rent-1',
  storageBucket: 'bike-rent-1.appspot.com',
  messagingSenderId: '564690812304',
  appId: '1:564690812304:web:1b7bda1671f05563120715',
  measurementId: 'G-T3F5QMM3Z9',
};

export const stagingFirebaseConfig = {
  apiKey: 'AIzaSyCf2quMh42pQdag6JeWBg58MhJPQMtq7I4',
  authDomain: 'bike-rent-user-service-staging.firebaseapp.com',
  projectId: 'bike-rent-user-service-staging',
  storageBucket: 'bike-rent-user-service-staging.appspot.com',
  messagingSenderId: '930947128674',
  appId: '1:930947128674:web:cb55be16a32aaa2aa4a03b',
  measurementId: 'G-Z9YMD84K5T',
};

export const productionFirebaseConfig = {
  apiKey: 'AIzaSyClEblfouxFjmlK6YraSM2GkXDfL69OJko',
  authDomain: 'booking.bike.rent',
  projectId: 'bike-rent-user-service-prod',
  storageBucket: 'bike-rent-user-service-prod.appspot.com',
  messagingSenderId: '434500247857',
  appId: '1:434500247857:web:b0342050f0256599b05010',
  measurementId: 'G-SPCT0GPCCQ',
};
